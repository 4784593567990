import wait from "./wait.js"

// internal constants
const DEFAULT_DELAY = 350 // ms

export async function slideDown ( el, delay = DEFAULT_DELAY ) {
    Object.assign( el.style, {
        display: "block",
        height: "",
        overflow: "hidden",
        position: "absolute",
        visibility: "hidden",
    })

    const height = el.clientHeight

    Object.assign( el.style, {
        height: "0px",
        position: "",
        visibility: "",
    })

    // wait for proper init transtion
    await wait( 50 )
    el.style.height = `${ height }px`

    // wait for finish transition
    await wait( delay )

    el.style.overflow = ""

    return
}

export async function slideUp ( el, delay = DEFAULT_DELAY ) {
    Object.assign( el.style, {
        height: "0px",
        overflow: "hidden",
    })

    // wait for finish transition
    await wait( delay )

    Object.assign( el.style, {
        display: "none",
        overflow: "",
    })

    return
}

export async function toggleSlide ( el, delay ) {
    const computedStyle = window.getComputedStyle( el )
    const isHidden = computedStyle.getPropertyValue( "display" ) === "none"

    return isHidden ? slideDown( el, delay ) : slideUp( el, delay )
}
