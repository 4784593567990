import { slideUp, toggleSlide } from "marketingWebsite/utils/toggleSlide.js"
import { burgerButton, header, headerMenus, headerMenuWrappers } from "./elements.js"
import { MOBILE_BREAKPOINT } from "marketingWebsite/utils/constants.js"

const EXPANDED_MENU_CLASS_NAME = "mainHeaderNav-expandedMenu"
const MENU_WRAPPER_CLASS = ".mainMenu-wrapper"
const MOBILE_MENU_EXPANDED_CLASS_NAME = "mainHeader--mobileMenuExpanded"
const OPEN_CLASS_NAME = "open"

// Add accordion look and feel to mobile menu
export default function useMobileMenu () {
    burgerButton.addEventListener( "click", ( event ) => {
        event.preventDefault()
        burgerButton.classList.toggle( OPEN_CLASS_NAME )
        header.classList.toggle( MOBILE_MENU_EXPANDED_CLASS_NAME )

        const isOpen = burgerButton.classList.contains( OPEN_CLASS_NAME )

        burgerButton.ariaExpanded = isOpen

        if ( !isOpen ) {
            // collapse expanded submenu on closing mobile menu
            const expandedItem = header.querySelector( `.${ EXPANDED_MENU_CLASS_NAME }` )

            if ( expandedItem ) {
                const menu = expandedItem.querySelector( MENU_WRAPPER_CLASS )

                expandedItem.classList.remove( EXPANDED_MENU_CLASS_NAME )
                setTimeout( async () => await slideUp( menu ), 0 )
            }
        }
    })

    function handleClick ( menuItem ) {
        const isMobileMenuExpanded = header.classList.contains( MOBILE_MENU_EXPANDED_CLASS_NAME )

        if ( window.innerWidth > MOBILE_BREAKPOINT || !isMobileMenuExpanded ) {
            return
        }

        headerMenus.forEach( ( item ) => {
            const menu = item.querySelector( MENU_WRAPPER_CLASS )

            if ( item === menuItem ) {
                item.classList.toggle( EXPANDED_MENU_CLASS_NAME )
                setTimeout( async () => await toggleSlide( menu ), 0 )
            }
            else {
                item.classList.remove( EXPANDED_MENU_CLASS_NAME )
                setTimeout( async () => await slideUp( menu ), 0 )
            }
        })
    }

    function cleanUpMenus () {
        if ( window.innerWidth <= MOBILE_BREAKPOINT ) {
            return
        }

        headerMenuWrappers.forEach( ( item ) => {
            item.removeAttribute( "style" )
        })
    }

    headerMenus.forEach( ( menuItem ) => {
        menuItem.addEventListener( "click", () => handleClick( menuItem ) )
    })

    window.addEventListener( "resize", cleanUpMenus )
}
