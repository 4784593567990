import { MOBILE_BREAKPOINT } from "marketingWebsite/utils/constants.js"

const COUNTING_CLASS_NAME = "mainMenu--counting"
const MAIN_MENU_CLASS = ".mainMenu"
const SHIFTED_CLASS_NAME = "mainMenu--shifted"

// Adjust the x position of the dropdown menu if it doesn't fit in the window
export default function useDesktopMenu () {
    function adjustMenuPosition ( element ) {
        element.style.left = ""
        element.classList.remove( SHIFTED_CLASS_NAME )
        element.classList.add( COUNTING_CLASS_NAME )

        const { x } = element.getBoundingClientRect()
        const isShiftNeeded = x < 0

        element.classList.remove( COUNTING_CLASS_NAME )
        element.classList.toggle( SHIFTED_CLASS_NAME, isShiftNeeded )

        if ( isShiftNeeded ) {
            const xOffset = Math.round( -1 * x )
            element.style.left = `${ xOffset }px`
        }
    }

    function adjustMenus () {
        if ( window.innerWidth <= MOBILE_BREAKPOINT ) {
            return
        }

        document
            .querySelectorAll( MAIN_MENU_CLASS )
            .forEach( adjustMenuPosition )
    }

    window.addEventListener( "resize", adjustMenus )
    adjustMenus()
}
