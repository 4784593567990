import {
    useDesktopMenu,
    useMobileMenu,
    useStickyHeader
} from "marketingWebsite/general/index.js"

// Aggregate and calls functions that are common to all subpages for marketing site
useStickyHeader()
useDesktopMenu()
useMobileMenu()
