import { HEADER_HEIGHT } from "marketingWebsite/utils/constants.js"

import { header } from "./elements.js"

// Sticky header on scroll
export default function useStickyHeader () {
    window.addEventListener( "scroll", () => {
        const isSticked = window.scrollY > HEADER_HEIGHT
        header.classList.toggle( "sticky", isSticked )
    })
}
